@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Adlam+Unjoined:wght@400..700&display=swap');

:root {
    --primary-color: #4a4a4a;
    --secondary-color: #3677BF;
    --background-color: #f9f9f9;
    --card-background-color: #ffffff;
    --button-background-color: #3677BF;
    --button-text-color: #ffffff;
    --input-background-color: #ffffff;
    --input-border-color: #dcdcdc;
    --highlight-color: #ffcccc;
    --label-color: #8B96AA; /* New variable for dark grey label color */
    --value-color: #3d3c3c;
    --gradient-start: #bfdffe; /* original 88bbee / light bedfff / a7d2fc */
    --gradient-mid: #cceaff;  /* b8e1ff */
    --gradient-end: #ffcbdc; /* original ffccdd / light efc6d4 / ffccdd*/
    --hover-color: #669ACC;
    --button-color: #3677BF;
}

@keyframes liquidFlow1 {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
}

@keyframes liquidFlow2 {
    0%, 100% { background-position: 100% 50%; }
    50% { background-position: 0% 50%; }
}

@keyframes liquidFlow3 {
    0%, 100% { background-position: 50% 0%; }
    50% { background-position: 50% 100%; }
}

@keyframes liquidFlow4 {
    0%, 100% { background-position: 50% 100%; }
    50% { background-position: 50% 0%; }
}

@media (max-width: 1140px) {
    .staked-pool-actions .firstLine
    {
        font-size: 10px !important;
    }
    .staked-pool-actions .secondLine
    {
        font-size: 10px !important;
    }
}

@media (max-width: 880px) {
    .staked-pool-actions .firstLine
    {
        font-size: 10px !important;
    }
    .staked-pool-actions .secondLine
    {
        font-size: 10px !important;
    }
}

@media (max-width: 850px) {
    .pools-container {
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .staking-pools{
        padding-bottom: 160px !important;
    }

    .walletbalance span{
        font-size: 14px !important;
    }

    .openModalButton{
        width: 100% !important;
    }
    .pool {
        width: 100% !important; /* Ensure the pool takes up full width minus padding */
        margin-bottom: 20px;
        padding: 15px;
        box-sizing: border-box;
        background-color: var(--card-background-color);
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .staked-pools-container {
        flex-direction: column;
    }

    .staked-pool {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px !important;
        box-sizing: border-box;
    }
    .footer-nav-menu{
        display: flex;
    }
    .footer-menu-section{
        flex-direction: column;
    }

    .footer a{
        margin: 0 !important;
        margin-right: 10px !important;
    }
    .footer-img{
        justify-content: center !important;
    }

    .footer-logo-section{
        margin-bottom: 10px;
    }

    .staked-pool-actions{
        display: flex !important;
        flex-direction: column;
    }

    .staked-pool-actions .firstLine{
        font-size: 14px !important;
    }

    .staked-pool-actions .secondLine{
        font-size: 14px !important;
    }

    .header {
        flex-direction: column;
        padding: 10px 20px;
    }
    
    .header-logo {
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .wallet-info {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .modal-content,
    .overlay-modal .modal-content {
        width: 80% !important;
        max-width: 350px !important;
    }

    .header-logo img.logo-image {
        width: 40px;
        margin-right: 5px;
    }

    .footer {
        font-size: 14px;
        padding: 20px 10px;
    }
}

@media (max-width: 400px) {
    .footer-nav-menu
    {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
      gap: 10px; /* Adds space between grid items */
    }
  }


body {
    background: 
        radial-gradient(circle at 30% 30%, var(--gradient-start) 0%, transparent 50%),
        radial-gradient(circle at 70% 70%, var(--gradient-end) 0%, transparent 50%),
        radial-gradient(circle at 50% 50%, var(--gradient-mid) 0%, transparent 60%),
        radial-gradient(ellipse at 20% 80%, var(--gradient-start) 0%, transparent 70%),
        radial-gradient(ellipse at 80% 20%, var(--gradient-end) 0%, transparent 70%),
        radial-gradient(circle at 40% 60%, #c4e0ff 0%, transparent 40%),
        radial-gradient(circle at 60% 40%, #ffe6e6 0%, transparent 40%),
        linear-gradient(45deg, var(--gradient-start) 0%, var(--gradient-mid) 25%, #d9e8ff 50%, var(--gradient-mid) 75%, var(--gradient-end) 100%);
    background-size: 
        400% 400%,
        400% 400%,
        200% 200%,
        300% 300%,
        300% 300%,
        200% 200%,
        200% 200%,
        200% 200%;
    background-attachment: fixed;
    animation: 
        liquidFlow1 20s ease infinite,
        liquidFlow2 25s ease infinite,
        liquidFlow3 30s ease infinite,
        liquidFlow4 35s ease infinite;
    color: var(--primary-color);
    font-family: "Noto Sans Adlam Unjoined", sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.gradient-text {
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
    /* font-family: "Satoshi", "Satoshi Placeholder", sans-serif; */
}

h1, h2, h3, h4, h5, h6 {
    color: var(--value-color);
    font-family: "Noto Sans Adlam Unjoined", "Noto Sans Adlam Unjoined Placeholder", sans-serif;
    margin: 0;
    padding: 0;
}

a {
    color: var(--secondary-color);
    text-decoration: none;
}

a:hover {
    color: var(--highlight-color);
}

button {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-family: "Noto Sans Adlam Unjoined", sans-serif;
    margin-left: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
}

button:hover {
    background-color: var(--hover-color);
}

button:disabled {
    background-color: #8B96AA;
    cursor: not-allowed;
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea {
    background-color: var(--input-background-color);
        width: 100%;
    font-family: "Noto Sans Adlam Unjoined", sans-serif;
    font-size: 20px;
    font-weight: 600;
    outline: none;
    border: none;
    appearance: none;
    -moz-appearance: textfield;
    -webkit-appearance: none; 
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    margin: 0; /* Removes margin */
}

.input-field {
    font-size: 20px;
    width: 100%;
}

.balance-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #2D3992;
    margin-bottom: 10px;
}

.max-link {
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 8px;
    background-color: #8B96AA;
    width: 100%;
}

.max-link.selected{
    background-color: var(--button-color);
}


.staking-pools{
    padding-bottom: 120px;
}

.staking-pools.greyed-out {
    pointer-events: none;
    opacity: 0.5;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content Button{
    height: 45px;
}
.modal-content {
    background: var(--background-color);
    padding: 30px;
    border-radius: 12px;
    z-index: 1001;
    width: 450px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-warning h2{
    font-weight: 700;
    color: var(--value-color);
    font-size: 24px;
}
.modal-warning p{
    font-size: 16px;
    font-weight: 400;
    color: var(--value-color);
}
.modal-warning span{
    font-size: 18px;
    font-weight: 600;
    color: var(--value-color);
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    display: flex;
}

.modal-warning .timeSection{
    display: flex;
    justify-content: space-between;
}
.modal-content .amount-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    gap: 5px;
}

.modal-content .modal-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
}
.modal-content img{
    width: 40px;
    height: 40px;
}

.modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    font-weight: bold;
}

.modal-content p {
    margin: 10px 0;
    font-size: 16px;
    color: var(--primary-color);
}

.modal-content .input-container{
    display: flex;
    align-items: center;
    padding:10px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
}
.modal-content .input-container .input-currency{
    font-size: 20px;
    font-weight: 600;
    color: var(--value-color);
}
.modal-buttons,
.overlay-modal .modal-buttons {
    display: flex;
    justify-content: center;
    gap: 20px; /* Ensure adequate spacing between buttons */
    margin-top: 10px; /* Space between content and buttons */
}

.modal-buttons .modalCancle{
    background-color: #ffffff;
    color: #3677BF;
    border: 1px solid #3677BF;
    box-shadow: none;
}

.modal-buttons .modalCancle:hover{
    opacity: 70%;
}

.modal-buttons .modalStake{
    box-shadow: none;
}

.overlay-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.overlay-modal .modal-content {
    background: var(--background-color);
    padding: 30px;
    border-radius: 12px;
    z-index: 1001;
    width: 450px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.overlay-modal .modal-content .spinner {
    margin: 20px auto;
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--secondary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.pools-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.pool {
    background: var(--card-background-color);
    padding: 20px;
    border-radius: 12px;
    width: 30%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.pool:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pool .stake-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

}

.pool .stake-logo .logo-section{
    display: flex;
    align-items: center;
    gap: 8px;
}
.pool h2 {
    font-size: 25px; /* Increased font size */
    color: var(--value-color);
    text-align: right;
    font-weight: 700; /* Increased weight for more emphasis */
    letter-spacing: 1px; /* Added spacing for a more polished look */
}

.pool .stake-image{
    width: 40px;
    height: 40px;
}

.pool .stake-label{
    color: var(--value-color);
    font-weight: 700;
    font-size: 25px;
}
.pool p {
    font-size: 16px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
}

.pool p .label {
    font-weight: 400;
    color: var(--label-color);
    padding-right: 10px; /* Add spacing between label and value */
}

.pool .stakebutton{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    font-weight: 700;
    margin-top: 10px;
}

.pool p .value {
    text-align: right;
    color: var(--value-color);
    font-size: 18px;
    font-weight: 600;
}

.connect-wallet {
    text-align: center;
    margin-top: 20px;
}

.staked-pools-section {
    padding: 0 20px;
    margin-top: 20px;
}

.staked-pools-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.staked-pool {
    flex: 1;
    background: var(--background-color);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--input-border-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure the content and buttons are in a column layout */
    justify-content: space-between; /* Pushes the buttons to the bottom */
}

.staked-pool:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.staked-pool-info {
    margin-bottom: 20px;
    text-align: left;
    flex-grow: 1; /* Makes the info section take up the remaining space */
}

.staked-pool-info img{
    width: 40px;
    height: 40px;
}

.staked-pool-info h3 {
    font-size: 15px; /* Increased font size */
    font-weight: 700; /* Increased weight for more emphasis */
    text-align: right; /* Center the header text */
    color: (--value-color);
    letter-spacing: 1px; /* Added spacing for a more polished look */
}

.staked-pool-info label{
    font-size: 24px;
    color: var(--value-color);
    font-weight: 700;
}

.staked-pool-info .stake-logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.staked-pool-info .stake-logo .imageSection{
    display: flex;
    align-items: center;
    gap: 8px
}

.staked-pool-info p {
    font-size: 18px;
    margin: 12px 0px;
    display: flex;
    justify-content: space-between;
}

.staked-pool-info .label {
    font-weight: 400;
    color: var(--label-color); /* Label color */
    padding-right: 10px; /* Add spacing between label and value */
    font-size: 14px;
}

.staked-pool-info .value {
    text-align: right;
    color: var(--value-color);
    font-size: 16px;
    font-weight: 600;
}
.staked-pool-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
    grid-template-rows: repeat(2, auto); /* Creates two rows with automatic height */
    gap : 10px
}
.staked-pool-actions .firstLine {
    width: 100%;
    padding: 14px 14px;
    font-weight: 700;
    font-size: 16px;
    align-self: flex-start;
}

.staked-pool-actions .firstLine:hover {
    opacity: 70%;
}

.staked-pool-actions .firstLine:last-child {
    margin-bottom: 5px; /* Remove bottom margin from the last button */
}

.staked-pool-actions .secondLine {
    width: 100%;
    padding: 14px 14px;
    font-weight: 700;
    font-size: 16px;
    align-self: flex-start;
    background-color: #669ACC;
}

.staked-pool-actions .secondLine:last-child {
    margin-bottom: 5px; /* Remove bottom margin from the last button */
}

.staked-pool-actions .secondLine:hover {
    opacity: 70%;
}

.wallet-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.wallet-info p {
    margin: 0;
    font-family: "Noto Sans Adlam Unjoined", sans-serif;
    background: var(--input-background-color);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo img.logo-image {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.gradient-text {
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
}

.error-message p {
    background-color: var(--highlight-color);
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 20px;
}

.notice-message {
    color: #ff4747 !important; /* Forces this color to be applied */
    font-weight: 400;
    font-size: 14px;
}

.footer {
    background: none;
    text-align: center;
    font-size: 12px;
    color: var(--primary-color);
    box-shadow: none;
    margin-top: auto;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0px;
}

.footer .footer-menu-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}
.footer .footer-menu-section span{
    color: var(--value-color);
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.footer img{
    width: 30px;
    height: 30px;
}

.footer .footer-logo-section{
    display: flex;
    flex-direction: column;
}

.footer .footer-img{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
.footer .footer-img p{
    font-weight: 700;
    font-size: 14px;
    color: #000000;
}
.footer a {
    color: var(--secondary-color);
    text-decoration: none;
    margin: 0 10px;
    font-weight: 400;
    font-size: 14px;
}

.footer a:hover {
    color: var(--highlight-color);
}

.footer a:last-child {
    margin-right: 0px; /* Remove bottom margin from the last button */
}

.footer span{
    font-weight: 400;
    font-size: 14px;
    color: #8B96AA;   
}

.connectWalletButton{
    width: 160px;
    height: 40px;
    border-radius: 10px;
    background-color: #3677BF;
    padding: 9px 20px;
}

.walletConnectButton{
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
}

.wrongNetworkButton
{
    background-color: red;
    width: 160px;
    height: 40px;
}

.walletbalance{
    display: flex;
    padding: 6px 10px;
    flex-direction: row;
    gap: 5px;
}
.walletbalance span{
    font-size: 16px;
    font-weight: 400;
    color: var(--value-color);
    white-space: nowrap;
}

.walletbalance .tokenBalance{
    color: #2D3992;
    font-size: 16px;
    font-weight: 400;
}

.openModalButton{
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width: 160px;
    height: 40px;
    justify-content: center;
}